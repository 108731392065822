import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { createRefetchContainer, graphql } from 'react-relay';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { fromGlobalId } from '../../helpers/ID';
import MoreButton from '../common/buttons/MoreButton';
import BaseGridContainer from '../common/BaseGridContainer';
import TableFieldMeta from '../../common/TableFieldMeta';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';

function AccountList(props) {
  const {
    relay,
    viewer,
    filter,
    postColumns,
  } = props;
  filter.setRelay(relay);
  const [statusIn, setStatusIn] = React.useState(filter.params.statusIn);

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('name', 'Account Name'),
    TableFieldMeta.field('referenceNumber', 'Reference Number'),
    {
      accessor: node => node,
      Header: 'Tenant',
      id: 'tenant',
      Cell: row => (<Link href={`/tenant/${fromGlobalId(row.value.tenant.id)[1]}`}>{row.value.tenant.name}</Link>),
    },
    TableFieldMeta.enum('AccountType', 'Account Type', node => node.accountType),
    TableFieldMeta.enum(process.env.DEPLOYMENT_CODE === 'UK' ? 'UKAccountStatus' : 'AccountStatus', 'Account Status', node => node.accountStatus),
    {
      id: 'more',
      accessor: node => node,
      Header: '',
      Cell: row => (
        <MoreButton href={`/account/${fromGlobalId(row.value.id)[1]}`} />),
    },
  ];

  if (postColumns) columns.push(...postColumns);

  return (
    <BaseGridContainer>
      {/* Application Type */}
      <InnerTabFilterSelectField
        enumType="AccountType"
        label="Account Type"
        value={filter.params.accountType}
        onChange={e => filter.updateVar('accountType', e.target.value)}
      />

      <InnerTabFilterSelectField
        enumType={process.env.DEPLOYMENT_CODE === 'UK' ? 'UKAccountStatus' : 'AccountStatus'}
        label="Account Status"
        withEmpty={false}
        multiple
        value={statusIn}
        valueDescriber={(enumMeta, enumKey) => enumMeta[enumKey].enumValue}
        onChange={(e) => {
          setStatusIn(e.target.value);
          filter.updateVar('statusIn', e.target.value);
        }}
      />

      <InnerTabFilterGridItemTextField
        id="search-filter"
        label="Custom Search"
        defaultValue={filter.params.search}
        helperText="User email, Name, ID, Account name"
        onChange={e => filter.updateVar('search', e.target.value)}
      />

      <Grid item xs={12}>
        <TablePaginator
          filter={filter}
          columns={columns}
          getDataNode={() => (viewer ? viewer.clientAccounts : null)}
        />
      </Grid>
    </BaseGridContainer>
  );
}

const query = graphql`
  query AccountListContainerQuery (
    $first: Int
    $offset: Int
    $accountType: String
    $search: String
    $tenantId: ID
    $adviserId: ID
    $statusIn: [AccountStatus]
  ) {
    viewer {
      ...AccountList_viewer
      @arguments (
        first: $first
        offset: $offset
        accountType: $accountType
        search: $search
        tenantId: $tenantId
        adviserId: $adviserId
        statusIn: $statusIn
      )
    }
  }
`;


const container = createRefetchContainer(
  AccountList,
  {
    viewer: graphql`
      fragment AccountList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        accountType: {type: String}
        search: {type: String}
        tenantId: {type: ID}
        adviserId: {type: ID}
        statusIn: {type: "[AccountStatus]"}
      ) {
        
        clientAccounts(
          first: $first
          offset: $offset
          accountType: $accountType
          search: $search
          tenant: $tenantId
          adviser: $adviserId
          statusInV2Typed: $statusIn
          orderBy: "-createdAt"
        ) @connection(key: "Accounts_clientAccounts") {
          edges {
            node {
              id
              name
              referenceNumber
              accountStatus
              accountType
              createdAt
              updatedAt
              tenant {
                id
                name
              }
              accountPtr{
                id
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ tenantId, adviserId }) => ({
    tenantId: tenantId || null,
    adviserId: adviserId || null,
    search: null,
    statusIn: ['ACCOUNT_APPROVED'],
  }),
  {
    debounceFields: ['search'],
  },
));

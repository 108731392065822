export const customEnumDefs = {
  ProcessedStatus: {
    false: {
      description: 'Processed',
      index: 0,
    },
    true: {
      description: 'Not Processed',
      index: 1,
    },
  },
  IgnoredStatus: {
    true: {
      description: 'Ignored',
      index: 0,
    },
    false: {
      description: 'Not Ignored',
      index: 1,
    },
  },
  MatchedStatus: {
    true: {
      description: 'Matched',
      index: 0,
    },
    false: {
      description: 'UnMatched',
      index: 1,
    },
  },
  VerifiedStatus: {
    true: {
      description: 'Verified',
      index: 0,
    },
    false: {
      description: 'Unverified',
      index: 1,
    },
  },
  DocsUploadedStatus: {
    true: {
      description: 'Uploaded',
      index: 0,
    },
    false: {
      description: 'Not Uploaded',
      index: 1,
    },
  },
  IsStaff: {
    true: {
      description: 'Staff',
      index: 0,
    },
    false: {
      description: 'Not Staff',
      index: 1,
    },
  },
  ActiveStatus: {
    true: {
      description: 'Active',
      index: 0,
    },
    false: {
      description: 'Inactive',
      index: 1,
    },
  },  
  AccountStatus: {
    // This was necessary because for AccountStatus underlying value is and integer
    // So that is needed for filtering.
    // However, resolvers resolves it to a string as in enumValue.
    1: {
      description: 'Not Initiated',
      enumValue: 'ACCOUNT_NOT_INITIATED',
      index: 0,
    },
    2: {
      description: 'Pending',
      enumValue: 'ACCOUNT_PENDING',
      index: 1,
    },
    3: {
      description: 'Submitted',
      enumValue: 'ACCOUNT_SUBMITTED',
      index: 2,
    },
    4: {
      description: 'Active',
      enumValue: 'ACCOUNT_APPROVED',
      index: 3,
    },
    6: {
      description: 'Closed',
      enumValue: 'ACCOUNT_CLOSED',
      index: 5,
    },
  },
  UKAccountStatus: {
    1: {
      description: 'Not Initiated',
      enumValue: 'ACCOUNT_NOT_INITIATED',
      index: 0,
    },
    2: {
      description: 'Pending (Awaiting Acknowledgement)',
      enumValue: 'ACCOUNT_PENDING',
      index: 1,
    },
    3: {
      description: 'Submitted',
      enumValue: 'ACCOUNT_SUBMITTED',
      index: 2,
    },
    4: {
      description: 'Active',
      enumValue: 'ACCOUNT_APPROVED',
      index: 3,
    },
    6: {
      description: 'Closed',
      enumValue: 'ACCOUNT_CLOSED',
      index: 5,
    },
    101: {
      description: 'Deceased',
      enumValue: 'ACCOUNT_DECEASED',
      index: 6,
    },
    102: {
      description: 'Trading Suspended',
      enumValue: 'ACCOUNT_TRADING_SUSPENDED',
      index: 7,
    },
    103: {
      description: 'Suspended',
      enumValue: 'ACCOUNT_SUSPENDED',
      index: 8,
    },
    104: {
      description: 'Continuing Account of Deceased (ISA)',
      enumValue: 'ACCOUNT_CONTINUED_ISA',
      index: 9,
    },
    105: {
      description: 'Void',
      enumValue: 'ACCOUNT_VOIDED_ISA',
      index: 10,
    },
    106: {
      description: 'Closing',
      enumValue: 'ACCOUNT_CLOSING',
      index: 11,
    },
  },
};

export const strToBool = (strVal) => {
  if (strVal === 'true') return true;
  if (strVal === 'false') return false;
  return null;
};

export const strToBoolInv = (strVal) => {
  if (strVal === 'true') return false;
  if (strVal === 'false') return true;
  return null;
};

export const boolToStr = (value) => {
  if (value === true) return 'true';
  if (value === false) return 'false';
  return null;
};

export const boolToStrInv = (value) => {
  if (value === true) return 'false';
  if (value === false) return 'true';
  return null;
};

/**
 * @flow
 * @relayHash e1113bc1fcef60cf10858fad2c48c6dc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeeType = "ADE" | "ADF" | "ASB" | "AVH" | "BUF" | "CHI" | "CHIP" | "CHUP" | "CON" | "COR" | "CRDT" | "FXB" | "GST" | "INT" | "MGN" | "MSB" | "PLF" | "PLT" | "PRO" | "RTA" | "RTP" | "STB" | "STBV" | "TAX" | "TRA" | "VHF" | "WDR" | "%future added value";
export type PaymentPeriod = "ANU" | "DAY" | "MON" | "ONE" | "%future added value";
export type FeeMutationInput = {|
  id?: ?string,
  template?: ?string,
  feeType: FeeType,
  paymentPeriod: PaymentPeriod,
  simpleTranche: boolean,
  enabled: boolean,
  clientMutationId?: ?string,
|};
export type FeeMutationVariables = {|
  input: FeeMutationInput
|};
export type FeeMutationResponse = {|
  +fee: ?{|
    +clientMutationId: ?string,
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +obj: ?{|
      +id: string,
      +enabled: boolean,
      +feeType: FeeType,
      +paymentPeriod: PaymentPeriod,
      +simpleTranche: boolean,
      +createdAt: any,
      +updatedAt: any,
      +feeComponents: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
      +template: {|
        +id: string,
        +fees: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
      |},
    |},
  |}
|};
export type FeeMutation = {|
  variables: FeeMutationVariables,
  response: FeeMutationResponse,
|};
*/


/*
mutation FeeMutation(
  $input: FeeMutationInput!
) {
  fee(input: $input) {
    clientMutationId
    errors {
      field
      messages
    }
    obj {
      id
      enabled
      feeType
      paymentPeriod
      simpleTranche
      createdAt
      updatedAt
      feeComponents {
        edges {
          node {
            id
          }
        }
      }
      template {
        id
        fees {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "FeeMutationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "fee",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FeeMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "obj",
        "storageKey": null,
        "args": null,
        "concreteType": "FeeNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "enabled",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "feeType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "paymentPeriod",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "simpleTranche",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feeComponents",
            "storageKey": null,
            "args": null,
            "concreteType": "FeeComponentNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FeeComponentNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeeComponentNode",
                    "plural": false,
                    "selections": (v2/*: any*/)
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "template",
            "storageKey": null,
            "args": null,
            "concreteType": "FeeTemplateNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "fees",
                "storageKey": null,
                "args": null,
                "concreteType": "FeeNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeeNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeeNode",
                        "plural": false,
                        "selections": (v2/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FeeMutation",
    "id": null,
    "text": "mutation FeeMutation(\n  $input: FeeMutationInput!\n) {\n  fee(input: $input) {\n    clientMutationId\n    errors {\n      field\n      messages\n    }\n    obj {\n      id\n      enabled\n      feeType\n      paymentPeriod\n      simpleTranche\n      createdAt\n      updatedAt\n      feeComponents {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      template {\n        id\n        fees {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c8b445160cffac25ff402225d0849a6';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AccountStatus = "ACCOUNT_APPROVED" | "ACCOUNT_CLOSED" | "ACCOUNT_NOT_INITIATED" | "ACCOUNT_PENDING" | "ACCOUNT_SUBMITTED" | "%future added value";
export type AccountType = "COMPANY" | "INDIVIDUAL" | "JOINT" | "SSD" | "TRUST" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountList_viewer$ref: FragmentReference;
declare export opaque type AccountList_viewer$fragmentType: AccountList_viewer$ref;
export type AccountList_viewer = {|
  +clientAccounts: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +referenceNumber: string,
        +accountStatus: AccountStatus,
        +accountType: ?AccountType,
        +createdAt: any,
        +updatedAt: any,
        +tenant: {|
          +id: string,
          +name: string,
        |},
        +accountPtr: {|
          +id: string
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: AccountList_viewer$ref,
|};
export type AccountList_viewer$data = AccountList_viewer;
export type AccountList_viewer$key = {
  +$data?: AccountList_viewer$data,
  +$fragmentRefs: AccountList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AccountList_viewer",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "clientAccounts"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "accountType",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "tenantId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "adviserId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "statusIn",
      "type": "[AccountStatus]",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "clientAccounts",
      "name": "__Accounts_clientAccounts_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "accountType",
          "variableName": "accountType"
        },
        {
          "kind": "Variable",
          "name": "adviser",
          "variableName": "adviserId"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "statusInV2Typed",
          "variableName": "statusIn"
        },
        {
          "kind": "Variable",
          "name": "tenant",
          "variableName": "tenantId"
        }
      ],
      "concreteType": "ClientAccountNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ClientAccountNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ClientAccountNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "referenceNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountStatus",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tenant",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TenantNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "accountPtr",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '039646a1c6972bf9fc961294eaa3f8f2';
module.exports = node;
